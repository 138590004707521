<template>
	<div>
		<div class="ser-form bg-fff">
			<Form :model="search" :label-width="100" inline label-colon>
				<FormItem label="设备号">
					<Input placeholder="请输入设备号" v-model="search.device_id" />
				</FormItem>
				<FormItem label="厂家">
					<Select v-model="search.factory_id" style="width: 200px;" @on-change="factoryChange">
						<Option :value="0">请选择</Option>
						<Option v-for="(item,index) in manufactor_list" :key="index" :value="item.id">{{ item.name }}</Option>
					</Select>
				</FormItem>
				<FormItem label="告警类型">
					<Select style="width: 200px;" v-model="search.type">
						<Option :value="0">请选择</Option>
						<Option v-for="(item,index) in alarm_list" :key="index" :value="item.type">{{ item.value }}
						</Option>
					</Select>
				</FormItem>
			</Form>
			<div class="text-r">
				<!-- <Button class="btn-r">重置</Button> -->
				<Button class="btn-r" type="primary" @click="init">查询</Button>
			</div>
		</div>
		<div class="table-list">
			<Table border :loading="loading" :columns="columns" :data="data">
				<template slot="content_text" slot-scope="{row}">
					<Tooltip placement="top" max-width="500" transfer>
						<div class="content-text">{{ row.content_text }}</div>
						<div slot="content">{{ row.content_text }}</div>
					</Tooltip>
				</template>
				<template slot="action" slot-scope="{row,index}">
					<span style="color: #2d8cf0; cursor: pointer;" v-if="row.status == 0" @click="openModal(index)">处理</span>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" @on-change="changePage" @on-page-size-change="changePageSize"
				:total="search.total" :page-size="search.pageSize" :page-size-opts="[15,30,50]" show-sizer show-total>
			</Page>
		</div>
		<Modal v-model="modal" title="处理告警">
			<Form ref="formInline" :model="formData" :rules="ruleInline" :label-width="100">
				<FormItem prop="remark" label="备注">
					<Input type="textarea" show-word-limit maxlength="100" :autosize="{minRows: 4,maxRows: 5}"
						v-model="formData.remark" placeholder="请输入备注"></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="modal = false">取消</Button>
				<Button @click="confirm" type="primary">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: "WarnList",
		data() {
			return {
				loading: false,
				modal: false,
				alarm_type: {},
				alarm_list: [],
				search: {
					device_id: '',
					factory_id: 0, // 厂家id
					type: 0,
					total: 0,
					page: 1,
					pageSize: 15,
				},
				manufactor_list: [], // 厂家列表
				formData: {
					id: null,
					device_id: null,
					remark: '',
				},
				ruleInline: {
					remark: [{ required: true, type: 'string', message: '请输入备注', trigger: 'blur' }]
				},
				columns: [
					{ title: '设备imei', key: 'device_id', minWidth: 200 },
					{ title: '厂家名称', key: 'factory_title', minWidth: 120 },
					{ title: '用户名称', key: 'uname', minWidth: 100 },
					{ title: '告警类型', key: 'type', minWidth: 100,
						render: (h, params) => {
							return h('span', this.alarm_type[params.row.type].value)
						}
					},
					{ title: '状态', key: 'status', minWidth: 100,
						render: (h, params) => {
							return h('Tag', {
								props: {
									color: params.row.status ? '#909399' : 'green'
								}
							}, params.row.status ? '已处理' : '未处理')
						}
					},
					{ title: '内容', slot: 'content_text', minWidth: 140 },
					{ title: '告警时间', key: 'create_date', minWidth: 150 },
					{ title: '处理时间', key: 'alarm_date', minWidth: 150 },
					{ title: '处理人', key: 'alarm_name', minWidth: 110 },
					{ title: '备注', key: 'remark', minWidth: 100 },
					{ title: '操作', slot: 'action', minWidth: 100, align: 'center', fixed: 'right',
						// render: (h, params) => {
						// 	return h('a', {
						// 		on: {
						// 			click: () => {
						// 				this.openModal(params.index)
						// 			}
						// 		}
						// 	}, '处理')
						// }
					},
				],
				data: [],
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				var that = this
				this.loading = true
				this.requestApi('/adm/get_alarm_record', this.search).then(function(res) {
					if (res.status) {
						if(JSON.stringify(that.alarm_list) == '{}'){
							that.alarm_list = res.alarm_type;
						}
						that.alarm_type = res.alarm_type;
						that.data = res.data.data
						that.search.total = res.data.total
						that.manufactor_list = res.manufactor_list;
					}
					that.$nextTick(function() {
						that.loading = false
					})
				})
			},
			openModal(index) {
				this.modal = true
				this.formData.remark = ''
				this.$refs.formInline.resetFields();
				this.formData.id = this.data[index].id
				this.formData.device_id = this.data[index].device_id
			},
			confirm() {
				var that = this
				this.$refs.formInline.validate((valid) => {
					if (valid) {
						that.requestApi('/adm/edit_alarm_record', that.formData).then(function(res) {
							if (res.status) {
								that.modal = false
								that.alertSucc(res.msg)
								that.init()
							} else {
								that.alertErr(res.msg)
							}
						})
					}
				})
			},
			changePage(page) {
				this.search.page = page
				this.init()
			},
			changePageSize(size) {
				this.search.page = 1
				this.search.pageSize = size
				this.init()
			},
			// 厂家切换
			factoryChange(id){
				this.search.type = 0;
				if(id == 0){
					this.alarm_list = JSON.parse(JSON.stringify(this.alarm_type));
				}else{
					for(let index in this.manufactor_list){
						let item = this.manufactor_list[index];
						if(item.id == id){
							this.alarm_list = JSON.parse(JSON.stringify(item.alarm_list));
						}
					}
				}
			}
		}

	}
</script>

<style scoped>
	.ser-form {
		padding: 12px 10px 6px;
		margin-bottom: 12px;
	}

	.text-r {
		text-align: right;
	}

	.table-list {
		padding: 12px 12px 0;
		background-color: #FFFFFF;
	}

	.btn-r {
		margin-right: 10px;
		margin-bottom: 6px;
	}
	
	.content-text {
		width: 140px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
